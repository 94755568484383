
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
          <b-row>
           <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_division.division')"
          label-for="division_id"
          >
          <b-form-select
            plain
            v-model="search.division_id"
            :options="divisionList"
            id="division_id"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
          </b-form-group>
      </b-col>
      <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_district.district')"
          label-for="district_id"
          >
          <b-form-select
            plain
            v-model="search.district_id"
            :options="districtList"
            id="district_id"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
          </b-form-group>
      </b-col>
      </b-row>
      <b-row>
      <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('org_pro_upazilla.upazilla')"
          label-for="upazilla_id"
          >
          <b-form-select
            plain
            v-model="search.upazilla_id"
            :options="upazilaList"
            id="upazilla_id"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
          </b-form-group>
      </b-col>
       <b-col>
         <b-form-group
          class="row"
          label-cols-sm="3"
          label-for="commodity_group_id"
          :label="$t('warehouse_information.warehouse_name')"
          >
          <b-form-select
            plain
            v-model="search.warehouse_id"
            :options="warehouseNameList"
            id="commodity_group_id"
            >
            <template v-slot:first>
              <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
          </b-form-group>
      </b-col>
    </b-row>
      <b-button type="button" variant="primary" @click="searchData">Search</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_information.warehouseServiceList') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
             {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                   <template v-slot:cell(index)="data">
                   {{ $n(data.index + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(division_name)="data">
                    {{ data.item.division_name }}
                  </template>
                   <template v-slot:cell(distict_name)="data">
                    {{ data.item.distict_name }}
                  </template>
                   <template v-slot:cell(upzila_name)="data">
                    {{ data.item.upzila_name }}
                  </template>
                  <template v-slot:cell(warehouse_name)="data">
                    {{ data.item.warehouse_name }}
                  </template>
                  <template v-slot:cell(remarks)="data">
                    {{ data.item.remarks }}
                  </template>
                  <template v-slot:cell(status)="data">
                    {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="pagination.totalRows"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="testClick"
                  />
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal id="modal-4" size="lg" :title="formTitle" ok-title="Close" ok-only ok-variant="danger">
      <Form :id="testId"/>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import Form from './Form'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseServiceList, warehouseServiceListToggleStatus } from '../../api/routes'

export default {
  components: {
    Form
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
         slOffset: 1
      },
      search: {
        warehouse_id: 0
      },
      testId: 0,
      warehouseNameList: [],
      upazilaList: [],
      districtList: []
    }
  },
  computed: {
    formTitle () {
      return (this.testId === 0) ? this.$t('warehouse_information.warehouseServiceEntry') : this.$t('warehouse_information.warehouse_sub_group_modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.WarehouseInfo.warehouseServices
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_information.division'), class: 'text-left' },
          { label: this.$t('warehouse_information.district'), class: 'text-left' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-left' },
          { label: this.$t('warehouse_information.warehouse_name'), class: 'text-left' },
          { label: this.$t('warehouse_information.remarks_Bn'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_name_bn' },
          { key: 'remarks_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_name' },
          { key: 'remarks' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
     divisionList: function () {
      return this.$store.state.commonObj.divisionList
    }
  },
   watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  async created () {
    this.mixinThis = this
    this.loadData()
  },
  mounted () {
    core.index()
    this.getWarehouseName()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        warehouse_id: 0,
        remarks: '',
        editable: false
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
       this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    async searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
     toggleStatus (item) {
            RestApi.deleteData(warehouseServiceBaseUrl, `${warehouseServiceListToggleStatus}/${item.id}`).then(response => {
                if (response.success) {
                    this.$store.dispatch('WarehouseInfo/updateWarehousServiceStatus', item)
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data Updated Successfully',
                        color: '#D6E09B'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Operation failed! Please, try again.'
                    })
                }
            })
        },
    remove (item) {
       this.$swal({
                title: this.$t('globalTrans.statusChangeMsg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.toggleStatus(item)
                }
            })
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      const result = await RestApi.getData(warehouseServiceBaseUrl, warehouseServiceList, params)
      if (result.success) {
       this.$store.dispatch('WarehouseInfo/addWarehouseServiceList', this.divisionListShow(result.data.data))
       this.paginationData(result.data)
      }
       this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
     getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getWarehouseName () {
      RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-info/list').then(response => {
        if (response.success) {
          this.warehouseNameList = response.data.data.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.warehouse_name_bn }
            } else {
              return { value: obj.id, text: obj.warehouse_name }
            }
          })
        }
      })
    },
      divisionListShow (data) {
      let tmpData = {}
      let tmpData2 = {}
      let tmpUpzila = {}
      const listData = data.map(item => {
        tmpData = this.$store.state.commonObj.divisionList.find(orgItem => orgItem.value === item.division_id)
        tmpData2 = this.$store.state.commonObj.districtList.find(orgItem => orgItem.value === item.district_id)
        tmpUpzila = this.$store.state.commonObj.upazilaList.find(orgItem => orgItem.value === item.upazilla_id)
        const orgData = { division_name: tmpData.text, division_name_bn: tmpData.text_bn, distict_name: tmpData2.text, distict_name_bn: tmpData2.text_bn, upzila_name: tmpUpzila.text, upzila_name_bn: tmpUpzila.text_bn }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
